import React, { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import styled, { css } from 'styled-components';

import SupplyInputBtn from './SupplyInputBtn';
import SelectBox from '@/components/common/SelectBox';
import { useAppSelector } from '@/hooks/useReduxHooks';
import {
  getComma,
  getSupplyPaymentPrice,
  getVatPaymentPrice,
} from '@/util/format';
import { isPorte } from '@/constants/common';

interface IProps {
  index: number;
  onRemove: (index: number) => void;
  isPorteKor?: boolean;
}

interface IChange {
  index: number;
  e: React.ChangeEvent<HTMLInputElement>;
}

function SupplyInputItem({ index, onRemove, isPorteKor = false }: IProps) {
  const methods = useFormContext();
  const { hospitalId } = useAppSelector((state) => state.user);
  const { productTypes } = useAppSelector((state) => state.user);
  const isSurgery =
    productTypes.length === 1
      ? true
      : String(methods.getValues(`supply.${index}.productType`));
  const { temporaryStorage } = useAppSelector((state) => state.refund);

  // 임시저장
  useEffect(() => {
    if (temporaryStorage.supplyDate) {
      let newValue = '';
      temporaryStorage.supply.forEach((item: any, idx) => {
        if (index === idx) {
          newValue = item.price;
        }
      });
      methods.setValue(`supply.${index}.price`, getComma(newValue));
      methods.setValue(
        `supply.${index}.supplyPrice`,
        getComma(getSupplyPaymentPrice(newValue.replace(/(,)/g, ''))),
      );
      methods.setValue(
        `supply.${index}.vatPrice`,
        getComma(
          getVatPaymentPrice(
            newValue.replace(/(,)/g, ''),
            getSupplyPaymentPrice(newValue.replace(/(,)/g, '')).toString(),
          ),
        ),
      );
    }
  }, [temporaryStorage]);

  useEffect(() => {
    if (isSurgery) {
      setTimeout(() => {
        methods.setFocus(`supply.${index}.price`);
      }, 100);
    }
  }, [isSurgery]);

  const lastValidPrice = useRef('');
  const onChange = ({ e: { target }, index }: IChange) => {
    // 금액이 0으로 시작하는거 방지
    const newtarget =
      target.value.charAt(0) === '0' ? target.value.substring(1) : target.value;
    methods.setValue(`supply.${index}.price`, newtarget.replace(/[^0-9]/g, ''));

    let newValue = target.value.replace(/(,)/g, '');
    if (+newValue === 0) {
      // 결제금액
      methods.setValue(`supply.${index}.price`, '0');
      // 공급가액
      methods.setValue(`supply.${index}.supplyPrice`, '0');
      // 부가세
      methods.setValue(`supply.${index}.vatPrice`, '0');
    } else {
      // 결제금액
      methods.setValue(`supply.${index}.price`, getComma(newValue));
      lastValidPrice.current = methods.getValues(`supply.${index}.price`);
      // 공급가액
      methods.setValue(
        `supply.${index}.supplyPrice`,
        getComma(getSupplyPaymentPrice(newValue)),
      );
      // 부가세
      methods.setValue(
        `supply.${index}.vatPrice`,
        getComma(
          getVatPaymentPrice(
            newValue,
            getSupplyPaymentPrice(newValue).toString(),
          ),
        ),
      );
    }
  };

  const onChangePrice = ({ e, index }: IChange) => {
    // 포르테 의원 + 한국인 예외처리
    if (isPorte(hospitalId) && !isPorteKor) {
      const { value } = e.target;
      const price = Number(value.replaceAll(',', ''));
      if (price > 5500000) {
        methods.setValue(`supply.${index}.price`, lastValidPrice.current);
        return;
      }
    }
    onChange({ e, index });
  };

  const onReset = () => {
    methods.setValue(`supply.${index}.price`, '');
    methods.setValue(`supply.${index}.productType`, productTypes[0]);
    methods.setValue(`supply.${index}.supplyPrice`, '');
    methods.setValue(`supply.${index}.vatPrice`, '');
  };

  return (
    <Wrapper>
      <InputItem>
        <Item flex={1}>{index + 1}</Item>
        <Item flex={4}>
          <SelectBox name={`supply.${index}.productType`} index={index} />
        </Item>
        <Item flex={3} alignRight>
          <Input
            {...methods.register(`supply.${index}.supplyPrice`)}
            placeholder='자동입력'
            disabled
            alignRight
          />{' '}
          원
        </Item>
        <Item flex={3} alignRight>
          <Input
            {...methods.register(`supply.${index}.vatPrice`)}
            placeholder='자동입력'
            disabled
            alignRight
          />
          원
        </Item>
        <Item flex={5} hasOutline>
          <Input
            {...methods.register(`supply.${index}.price`, {
              onChange: (e) => onChangePrice({ e, index }),
            })}
            disabled={Boolean(!isSurgery)}
            placeholder='의료 금액 입력'
            autoComplete='off'
            hasOutline
          />
          원
        </Item>
        <SupplyInputBtn index={index} onReset={onReset} />
      </InputItem>
    </Wrapper>
  );
}

const Wrapper = styled.div``;

const InputItem = styled.li`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.mono[10]};
`;

const Item = styled.div<{
  flex: number;
  hasOutline?: boolean;
  alignRight?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  gap: 10px;
  color: ${(props) => props.theme.mono[props.hasOutline ? 50 : 35]};
  flex: ${(props) => props.flex};
  line-height: ${(props) => props.theme.lineHeight.large};
  ${(props) =>
    props.alignRight &&
    css`
      justify-content: flex-start;
    `}
`;

const Input = styled.input<{ hasOutline?: boolean; alignRight?: boolean }>`
  padding: 11px 14px;
  border: ${(props) =>
    props.hasOutline ? `1px solid ${props.theme.grayColors.scale3}` : 'none'};
  border-radius: 4px;
  text-align: ${(props) => (props.hasOutline ? 'center' : 'right')};
  outline: none;
  :disabled {
    background-color: ${(props) =>
      props.hasOutline ? props.theme.mono[5] : '#ffffff'};
  }
  ::placeholder {
    text-align: ${(props) => (props.hasOutline ? 'center' : 'right')};
    color: ${(props) => props.theme.grayColors.scale3};
  }
  :focus {
    background-color: ${(props) => props.theme.blue[5]};
    border: 1px solid ${(props) => props.theme.blue[100]};
  }
  ${(props) =>
    props.alignRight &&
    css`
      width: 140px;
    `}
`;

export default SupplyInputItem;
